import { styled } from 'Theme/stitches.config';
import type * as Stitches from '@stitches/react';
import { BaseProps } from './BaseButtonType';
import KexIconLoader, { IconName } from 'DesignSystem/Icons/KexIconLoader';

type StyledButtonVariants = Stitches.VariantProps<typeof Root>;

export type IconButtonType = Omit<BaseProps, 'text' | 'children'> & {
  color: StyledButtonVariants['color'];
  size?: StyledButtonVariants['size'];
  icon: IconName;
};

function IconButton({
  color = 'Regular',
  disabled,
  focus,
  pressed,
  size,
  icon,
}: IconButtonType) {
  const Icon = KexIconLoader(icon);
  return (
    Icon && (
      <Root
        color={color}
        size={size}
        disabled={disabled}
        pressed={pressed}
        focus={focus}
      >
        <Icon size={size as any} />
      </Root>
    )
  );
}

const Root = styled('div', {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  w: 'fit-content',
  backgroundColor: '$interactivePrimaryBackgroundDefault',
  '&:focus-visible, &:focus': {
    outline: 'unset !important',
    boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActive',
  },
  variants: {
    color: {
      Regular: {
        backgroundColor: '$interactivePrimaryBackgroundDefault',
        path: {
          fill: '$onInteractivePrimary',
        },
        '&:hover': {
          background: '$interactivePrimaryBackgroundHover',
        },
      },
      Inverse: {
        backgroundColor: '$surface',
        path: {
          fill: '$onSurface',
        },
        '&:hover': {
          background: '$interactiveSecondaryBackgroundHover',
        },
        '&:focus-visible, &:focus': {
          boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActiveInverse',
        },
      },
      Secondary: {
        backgroundColor: '$surface',
        path: {
          fill: '$onSurface',
        },
        boxShadow: '0px 0px 0px 1px $colors$interactiveBorderSecondaryDefault',
        '&:hover': {
          boxShadow: '0px 0px 0px 2px $colors$interactiveBorderSecondaryHover',
        },
        '&:active': {
          background: '$interactiveSecondaryBackgroundPressed',
        },
        '&:focus-visible, &:focus': {
          outline: 'unset !important',
          boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActive',
        },
      },
    },
    size: {
      s: {
        wh: 40,
      },
      m: {
        wh: 48,
      },
    },
    disabled: {
      true: {
        backgroundColor: '$interactiveDisabled_1',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        path: {
          fill: '$interactiveDisabled_2',
        },
      },
    },
    pressed: {
      true: {},
    },
    focus: {
      true: { boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActive' },
    },
  },
  defaultVariants: {
    color: 'Regular',
  },
  compoundVariants: [
    {
      disabled: true,
      color: 'Inverse',
      css: {
        backgroundColor: '$interactiveDisabled_2',
        path: {
          fill: '$interactiveDisabled_1',
        },
      },
    },
    {
      disabled: true,
      color: 'Secondary',
      css: {
        backgroundColor: '$surface',
        boxShadow: '0px 0px 0px 2px $colors$interactiveDisabled_1',
        path: {
          fill: '$interactiveDisabled_1',
        },
      },
    },
    {
      pressed: true,
      color: 'Regular',
      css: {
        background: '$interactivePrimaryBackgroundPressed',
        '&:hover': {
          background: '$interactivePrimaryBackgroundPressed',
        },
      },
    },
    {
      size: 'm',
      color: 'Secondary',
      css: {
        wh: 46,
      },
    },
    {
      size: 's',
      color: 'Secondary',
      css: {
        wh: 38,
      },
    },
    {
      focus: true,
      color: 'Inverse',
      css: {
        boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActiveInverse',
      },
    },
  ],
});

export default IconButton;
